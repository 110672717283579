import Casa1921 from '../assets/Cachaca_mezcal_pisco_sotol_tequila/1921.png';
import Aldez from '../assets/Cachaca_mezcal_pisco_sotol_tequila/aldez.png';
import Arette from '../assets/Cachaca_mezcal_pisco_sotol_tequila/arette.png';
import Batanaga from '../assets/Cachaca_mezcal_pisco_sotol_tequila/batanga.png';
import Cazcanes from '../assets/Cachaca_mezcal_pisco_sotol_tequila/cazcanes.png';
import Erstwhile from '../assets/Cachaca_mezcal_pisco_sotol_tequila/erstwhile.png';
import Guerraseca from '../assets/Cachaca_mezcal_pisco_sotol_tequila/guerraseca.png';
import Lagritona from '../assets/Cachaca_mezcal_pisco_sotol_tequila/lagritona.png';
import Lamadrina from '../assets/Cachaca_mezcal_pisco_sotol_tequila/lamadrina.png';
import Nfinite from '../assets/Cachaca_mezcal_pisco_sotol_tequila/nfinite.png';
import Micasa from '../assets/Cachaca_mezcal_pisco_sotol_tequila/micasa.png';
import Plumanegra from '../assets/Cachaca_mezcal_pisco_sotol_tequila/plumanegra.png';
import Soulpremium from '../assets/Cachaca_mezcal_pisco_sotol_tequila/soulpremium.png';
import Tequilatapatio from '../assets/Cachaca_mezcal_pisco_sotol_tequila/tequilatapatio.png';
import Wahaka from '../assets/Cachaca_mezcal_pisco_sotol_tequila/wahaka.png';
import Yeyo from '../assets/Cachaca_mezcal_pisco_sotol_tequila/yeyo.png';
import Zarpado from '../assets/Cachaca_mezcal_pisco_sotol_tequila/zarpado.png';

export const Cachaca_mezcal_pisco_sotol_tequila = [
    {
      img: Aldez,
      url: 'https://aldeztequila.com/',
      title: 'Aldez Tequila',
      tags: ['tequila']
    },
    {
      img: Arette,
      url: 'https://www.tequilaarette.com/',
      title: 'Arette Tequila',
      tags: ['tequila']
    },
    {
      img: Batanaga,
      url: 'https://www.batangatequila.com/',
      title: 'Batanaga Tequila',
      tags: ['tequila']
    },
    {
      img: Casa1921,
      url: 'https://tequila1921.com/en/home/',
      title: 'Casa 1921',
      tags: ['tequila']
    },
    {
      img: Cazcanes,
      url: 'https://www.cazcanes.com/',
      title: 'Cazcanes Tequila',
      tags: ['mezcal']
    },    {
      img: Erstwhile,
      url: 'https://www.erstwhilemezcal.com/',
      title: 'Erstwhile Mezcal',
      tags: ['mezcal']
    },
    {
      img: Guerraseca,
      url: 'https://guerraseca.com/',
      title: 'Guerra Seca Sotol',
      tags: ['mezcal', 'sotol']
    },
    {
      img: Lagritona,
      url: 'https://lagritona.com/',
      title: 'La Gritona',
      tags: ['mezcal']
    },
    {
      img: Lamadrina,
      url: 'https://lamadrinapisco.com/',
      title: 'La Madrina Pisco',
      tags: ['pisco']
    },
    {
      img: Nfinite,
      url: 'https://drinknfinite.com/',
      title: 'N-Finite Spirits',
      tags: ['tequila']
    },    {
      img: Micasa,
      url: 'https://www.micasatequila.com/',
      title: 'Mi Casa Tequila',
      tags: ['tequila']
    },
    {
      img: Plumanegra,
      url: 'https://mezcalplumanegra.com/',
      title: 'Pluma Negra Mezcal',
      tags: ['mezcal']
    },
    {
      img: Soulpremium,
      url: 'https://www.soulcachaca.com/',
      title: 'Soul Premium Cachaca',
      tags: ['cachaca']
    },
    {
      img: Tequilatapatio,
      url: 'https://www.tequilatapatio.mx/en/',
      title: 'Tequila Tapatio',
      tags: ['tequila']
    },
    {
      img: Wahaka,
      url: 'https://www.wahakamezcal.com/',
      title: 'Wahaka Mezcal',
      tags: ['mezcal']
    },
    {
      img: Yeyo,
      url: 'https://www.yeyotequila.com/',
      title: 'Yeyo Tequila',
      tags: ['tequila']
    },
    {
      img: Zarpado,
      url: 'https://tequilazarpado.com/',
      title: 'Zarpado Tequila',
      tags: ['tequila']
    }
  ];
