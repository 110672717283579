import img10th from "../assets/Armangac_bourbon_cognac_whiskey/10th.png";
import Img291 from "../assets/Armangac_bourbon_cognac_whiskey/291.png";
import Amrut from "../assets/Armangac_bourbon_cognac_whiskey/amrut.png";
import Ardnamurchan from "../assets/Armangac_bourbon_cognac_whiskey/ardnamurchan.png";
import Armorik from "../assets/Armangac_bourbon_cognac_whiskey/armorik.png";
import Augusta from "../assets/Armangac_bourbon_cognac_whiskey/augusta.png";
import Bastille from "../assets/Armangac_bourbon_cognac_whiskey/bastille.png";
import Copper from "../assets/Armangac_bourbon_cognac_whiskey/copper.png";
import Dartigalongue from "../assets/Armangac_bourbon_cognac_whiskey/dartigalongue.png";
import Delord from "../assets/Armangac_bourbon_cognac_whiskey/delord.png";
import DGD from "../assets/Armangac_bourbon_cognac_whiskey/dgd.png";
import Docswinsons from "../assets/Armangac_bourbon_cognac_whiskey/docswinsons.png";
import DryFly from "../assets/Armangac_bourbon_cognac_whiskey/dryfly.png";
import Dupeyrat from "../assets/Armangac_bourbon_cognac_whiskey/dupeyrat.png";
import Fileybay from "../assets/Armangac_bourbon_cognac_whiskey/fileybay.png";
import Filibuster from "../assets/Armangac_bourbon_cognac_whiskey/filibuster.png";
import Fukano from "../assets/Armangac_bourbon_cognac_whiskey/fukano.png";
import Glenallachie from "../assets/Armangac_bourbon_cognac_whiskey/glenallachie.png";
import Hakata from "../assets/Armangac_bourbon_cognac_whiskey/hakata.png";
import Ikikko from "../assets/Armangac_bourbon_cognac_whiskey/ikikko.png";
import Indri from "../assets/Armangac_bourbon_cognac_whiskey/indri.png";
import Ironfish from "../assets/Armangac_bourbon_cognac_whiskey/ironfish.png";
import IsleOfRaasay from "../assets/Armangac_bourbon_cognac_whiskey/isleofraasay.png";
import JMattingly from "../assets/Armangac_bourbon_cognac_whiskey/jmattingly.png";
import Kilchoman from "../assets/Armangac_bourbon_cognac_whiskey/kilchoman.png";
import Lochlea from "../assets/Armangac_bourbon_cognac_whiskey/lochlea.png";
import MH from "../assets/Armangac_bourbon_cognac_whiskey/mh.png";
import Monnet from "../assets/Armangac_bourbon_cognac_whiskey/monnet.png";
import Northcross from "../assets/Armangac_bourbon_cognac_whiskey/northcross.png";
import Pokeno from "../assets/Armangac_bourbon_cognac_whiskey/pokeno.png";
import Portaskaig from "../assets/Armangac_bourbon_cognac_whiskey/portaskaig.png";
import Redline from "../assets/Armangac_bourbon_cognac_whiskey/redline.png";
import SeedSpirit from "../assets/Armangac_bourbon_cognac_whiskey/ss.png";
import Shinju from "../assets/Armangac_bourbon_cognac_whiskey/shinju.png";
import Taconic from "../assets/Armangac_bourbon_cognac_whiskey/taconic.png";
import Wheelhorse from "../assets/Armangac_bourbon_cognac_whiskey/wheelhorse.png";
import Wolfburn from "../assets/Armangac_bourbon_cognac_whiskey/wolfburn.png";


export const Armangac_bourbon_cognac_whiskey = [
  {
    img: img10th,
    url: 'https://10thwhiskey.com/',
    title: '10th Mountain Whiskey & Spirits',
    tags: ['whiskey']
  },
  {
    img: Ardnamurchan,
    url: 'https://www.adelphidistillery.com/',
    title: 'Adelphi Distillery',
    tags: ['whiskey']
  },
  {
    img: Amrut,
    url: 'https://www.amrutdistilleries.com/',
      title: 'Amrut Distilleries',
    tags: ['whiskey']
  },
  {
    img: Augusta,
    url: 'https://augustakydistillery.com/',
      title: 'Augusta Distillery',
    tags: ['whiskey', 'bourbon']
  },
  {
    img: Bastille,
    url: 'https://www.bastillewhisky.com/age-verify.php?lang=en',
    title: 'Bastille',
    tags: ['whiskey']
  },
    {
    img: Copper,
    url: 'https://copperandcaskspirits.com/',
    title: 'Copper & Cask',
    tags: ['whiskey']
  },
  {
    img: Dartigalongue,
    url: 'https://www.dartigalongue.com/en/',
    title: 'Dartigalongue',
    tags: ['armagnac']
  },
  {
    img: Delord,
    url: 'https://www.armagnacdelord.com/en/',
    title: 'Delord  Armangac',
    tags: ['armagnac']
  },
  {
    img: DGD,
    url: 'https://www.dancinggoat.com/',
    title: 'Dancing Goat Distillery',
    tags: ['whiskey', 'bourbon']
   },
  {
   img: Img291,
   url: "https://distillery291.com/",
   title: 'Distillery 291',
   tags: ['whiskey', 'bourbon']
   },
  {
    img: Armorik,
    url: 'https://armorik.bzh/en/home/',
    title: 'Distillerie Warenghem',
    tags: ['whiskey']
  },
  {
    img: Docswinsons,
    url: 'https://www.docswhiskey.com/',
    title: 'Doc Swinsons',
    tags: ['whiskey', 'bourbon']
  },
  {
    img: DryFly,
    url: 'https://dryflydistilling.com/',
    title: 'Dry Fly Distilling',
    tags: ['whiskey', 'bourbon']
  },
  {
    img: Dupeyrat,
    url: 'https://www.distilleriedupeyrat.com/',
    title: 'Distillerie Du Peyrat',
    tags: ['armagnac']
  },
  {
    img: Filibuster,
    url: 'https://filibusterbourbon.com/',
    title: 'Filibuster Barrels',
    tags: ['whiskey', 'bourbon']
  },
  {
    img: Fukano,
    url: 'https://www.fukanowhisky.com/',
    title: 'Fukano Whisky',
    tags: ['whiskey']
  },
  {
    img: Hakata,
    url: 'https://www.hakatawhisky.com/',
    title: 'Hakata Whisky',
    tags: ['whiskey']
  },
  {
    img: Ikikko,
    url: 'https://ikinokura.co.jp/lang/en.html',
      title: 'Ikinokura Distillery',
    tags: ['whiskey']
  },
  {
    img: Indri,
    url: 'https://www.indri.in/',
    title: 'Indri',
    tags: ['whiskey']
  },
  {
    img: Ironfish,
    url: 'https://ironfishdistillery.com/',
      title: 'Iron Fish Distillery',
    tags: ['whiskey', 'bourbon']
  },
  {
    img: IsleOfRaasay,
    url: 'https://raasaydistillery.com/raasay-whisky/',
    title: 'Isle Of Raasay',
    tags: ['whiskey']
  },
  {
    img: JMattingly,
    url: 'https://jmattingly1845.com/',
    title: 'J Mattingly 1845 Distillery',
    tags: ['whiskey']
  },
  {
    img: Kilchoman,
    url: 'https://www.kilchomandistillery.com/',
      title: 'Kilchoman Distillery',
    tags: ['whiskey']
  },
  {
    img: Lochlea,
    url: 'https://www.lochleadistillery.com/',
      title: 'Lochlea Distillery',
    tags: ['whiskey']
  },
  {
    img: MH,
    url: 'https://mh-distillery.com/',
      title: 'M&H Distillery',
    tags: ['whiskey']
  },
  {
    img: Monnet,
    url: 'https://www.monnet.com/?lang=en',
    title: 'Monnet Cognac',
    tags: ['cognac']
  },
  {
    img: Northcross,
    url: 'https://www.northcrosswhiskey.com/',
      title: 'Northcross Irish Whiskey',
    tags: ['whiskey']
  },
  {
    img: Pokeno,
    url: 'https://pokenowhisky.com/',
    title: 'The Pokeno Whisky Co',
    tags: ['whiskey']
  },
  {
    img: Portaskaig,
    url: 'https://portaskaig.com/',
    title: 'Port Askaig',
    tags: ['whiskey']
  },
  {
    img: Redline,
    url: 'https://www.redlinebourbon.com/',
    title: 'Red Line Bourbon',
    tags: ['whiskey', 'bourbon']
  },
  {
    img: SeedSpirit,
    url: 'https://seedandspiritdistilling.com/',
    title: 'Seed & Spirit',
    tags: ['whiskey', 'bourbon']
  },
  {
    img: Shinju,
    url: 'https://www.shinjuwhisky.com/',
    title: 'Shinju Whisky',
    tags: ['whiskey']
  },
  {
    img: Fileybay,
    url: 'https://www.spiritofyorkshire.com/filey-bay/',
    title: 'Spirit of Yorkshire',
    tags: ['whiskey']
  },
  {
    img: Taconic,
    url: 'https://www.taconicdistillery.com/',
      title: 'Taconic Distillery',
    tags: ['whiskey', 'bourbon']
  },
  {
    img: Glenallachie,
    url: 'https://theglenallachie.com/',
    title: 'The Glen Allachie Distillery',
    tags: ['whiskey']
  },
  {
    img: Wheelhorse,
    url: 'https://wheelhorsewhiskey.com/',
    title: 'Wheel Horse Whiskey',
    tags: ['whiskey']
  },  
  {
    img: Wolfburn,
    url: 'https://wolfburn.com/pages/home',
    title: 'Wolfburn Distillery',
    tags: ['whiskey']
  }
];